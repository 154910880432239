<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        v-model="query.title"
                        placeholder="标题"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                        clearable
                    />
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.articleType"
                        clearable
                        :codeType="'article_type'"
                        placeholder="栏目"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="true"
                        placeholder="展示国家"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.fileType"
                        placeholder="类型"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="图文" value="image"></el-option>
                        <el-option label="视频" value="video"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.brandCode"
                        :multiple="false"
                        placeholder="品牌"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.publishStatus"
                        placeholder="状态"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="即将发布" value="0"></el-option>
                        <el-option label="发布中" value="1"></el-option>
                        <el-option label="已结束" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        placeholder="是否启用"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.recommend"
                        placeholder="是否推荐"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="query.newed" placeholder="是否最新" clearable style="width: 180px" class="filter-item">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        查询
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                        重置
                    </el-button>
                </el-form-item>
                <div>
                    <el-button
                        class="filter-item"
                        style="margin: 10px 0px 0px 0px"
                        type="success"
                        icon="el-icon-edit"
                        @click="handleCreate"
                    >
                        添加
                    </el-button>
                    <el-button class="filter-item" style="margin: 10px 0px 0px 10px" type="success" icon="el-icon-edit" @click="handleCopy">
                        复制文章
                    </el-button>
                </div>
            </el-form>
        </div>

        <el-table
            v-loading="listLoading"
            :data="list.list"
            border
            stripe
            style="width: 100%; margin-top: 20px"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" :selectable="handleDisable"></el-table-column>
            <el-table-column label="栏目" prop="articleType" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.articleType" :codeType="'article_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="countryCodes" label="展示国家" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.countryCodes" :key="index">
                        {{ item }}
                        <span v-if="scope.row.countryCodes.length != 1 && index != scope.row.countryCodes.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="标题" prop="title" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="类型" prop="fileType" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.fileType == 'image'">{{ '图文' }}</span>
                    <span v-if="row.fileType == 'video'">{{ '视频' }}</span>
                </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="imageUrl" label="封面" align="center">
                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl + '?x-oss-process=style/list_s'"
                        fit="fill"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="brandCodes" label="品牌" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.brandCodes" :key="index">
                        {{ item }}
                        <span v-if="scope.row.brandCodes.length != 1 && index != scope.row.brandCodes.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="publishStatus" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.publishStatus == 0" style="color: red">{{ '即将发布' }}</span>
                    <span v-if="row.publishStatus == 1" style="color: green">{{ '发布中' }}</span>
                    <span v-if="row.publishStatus == 2" style="color: gray">{{ '已结束' }}</span>
                </template>
            </el-table-column>

            <el-table-column label="类目名称" prop="goodsClassifyId" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span >{{ getDictValueByGoodsClassifyId(row) }}</span>     
                </template>
            </el-table-column>
 
            <el-table-column label="开始时间" prop="startTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="结束时间" prop="endTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :render-header="renderSortHeader" prop="sort" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.sort"
                        :min="0"
                        οnkeyup="value=value.replace(/[^\d]/g,'')"
                        oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                        @keyup.enter.native="putSortArticle(scope.row)"
                        placeholder="请输入内容"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="是否启用" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <!-- <span v-if="scope.row.status">启用</span>
                    <span v-if="!scope.row.status">禁用</span> -->
                    <el-switch v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="recommend" label="是否推荐" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <!-- <span v-if="scope.row.status">启用</span>
                    <span v-if="!scope.row.status">禁用</span> -->
                    <el-switch
                        v-model="scope.row.recommend"
                        active-color="#13ce66"
                        :disabled="scope.row.articleType != 'Blog'"
                        @change="changeStatus(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="newed" label="是否最新" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <!-- <span v-if="scope.row.status">启用</span>
                    <span v-if="!scope.row.status">禁用</span> -->
                    <el-switch
                        v-model="scope.row.newed"
                        active-color="#13ce66"
                        :disabled="scope.row.articleType != 'Blog'"
                        @change="changeStatus(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="operator" label="操作人" align="center"></el-table-column>

            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog
            :title="formTitle"
            :visible.sync="addVisible"
            @close="closeForm()"
            v-if="addVisible"
            :close-on-click-modal="false"
            width="950px"
        >
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addArticle"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item label="栏目" prop="articleType">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addArticle.articleType"
                        clearable
                        :codeType="'article_type'"
                        placeholder="栏目"
                        @change="articleChange"
                    ></dataSelect>
                </el-form-item>
                <el-form-item label="展示国家" prop="countryCodes">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <el-checkbox-group v-model="addArticle.countryCodes" @change="handleCheckedCitiesChange" style="width: 600px">
                        <el-checkbox v-for="country in countrysDicts" :label="country.code" :key="country.code"
                            >{{ country.name }}({{ country.code }})</el-checkbox
                        >
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="类型" prop="fileType">
                    <el-radio-group v-model="addArticle.fileType" @change="fileTypeChange()">
                        <el-radio :disabled="imageShowStatus" label="image">图文</el-radio>
                        <el-radio :disabled="videoShowStatus" label="video">视频</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="品牌" prop="brandCodes">
                    <el-select class="filter-item" v-model="brand" placeholder="品牌" style="width: 180px" clearable @change="brandChange">
                        <el-option v-if="myTenantid == '1001001000' || myTenantid == '1001001006'" :disabled="aigoBrandStatus" value="AigoStar">AigoStar</el-option>
                        <el-option v-if="myTenantid == '1001001000' || myTenantid == '1001001006'" :disabled="noblezaBrandStatus" value="Nobleza">Nobleza</el-option>
                        <el-option v-if="myTenantid == '1001002001'"  value="Unico">Unico</el-option>
                    </el-select>
                    <!-- <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="brand"
                        clearable
                        :codeType="'brand'"
                        placeholder="品牌"
                    ></dataSelect> -->
                </el-form-item>


                <el-form-item label="类目" v-if="addArticle.articleType=='Video'" prop="goodsClassifyId"  >      
                    <el-select class="filter-item" v-model="addArticle.goodsClassifyId" placeholder="类目" style="width: 180px" clearable>
                        <el-option v-for="item in goodsClassifyDicts" :key="item.goodsClassifyId" :label="item.title"
								:value="item.goodsClassifyId">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="发布时间" prop="startTime">
                    <el-date-picker
                        v-model="valuePicker"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="imageUrl" label="图片" style="width: 600px">
                    <el-col :span="10">
                        <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl"> </imgUpload>
                    </el-col>
                    <el-col :span="10" style="margin-top: 150px">
                        <span>建议尺寸：466*280，<=200KB</span>
                    </el-col>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input
                        v-model="addArticle.sort"
                        :min="0"
                        style="width: 100%"
                        oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="status">
                    <el-switch v-model="addArticle.status" active-color="#13ce669"></el-switch>
                </el-form-item>
                <el-form-item label="是否最新" prop="newed" v-if="addArticle.articleType == 'Blog'">
                    <el-switch v-model="addArticle.newed" active-color="#13ce669"></el-switch>
                </el-form-item>
                <el-form-item label="是否推荐" prop="recommend" v-if="addArticle.articleType == 'Blog'">
                    <el-switch v-model="addArticle.recommend" active-color="#13ce669"></el-switch>
                </el-form-item>
                <!-- </div> -->

                <el-form-item label="多语言配置" v-if="addArticle.fileType == 'image'" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>标题：</span>
                        <el-input style="margin-top: 20px; width: 450px" v-model="title" placeholder="请输入标题" label="标题"></el-input>
                        <el-button type="text" @click="contentToOtherLang" style="margin-left: 55px">同步到其他语言</el-button>
                    </div>
                    <div style="margin-top: 20px">
                        <span>内容：</span>
                        <wangEditer ref="wangEditer" :conten="contentValue" style="margin-top: 10px" />
                    </div>
                    <div style="margin-top: 20px">
                        <el-button type="text" @click="openEdit">编辑富文本源码</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="视频" v-if="addArticle.fileType == 'video'" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleVideoSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>标题：</span>
                        <el-input style="margin-top: 20px; width: 450px" v-model="title" placeholder="请输入标题" label="标题"></el-input>
                    </div>
                    <div style="margin-top: 20px">
                        <span>上传视频：</span>
                        <videoUpload ref="videoUpload" :video="videoUrl" @event="changeVideoUrl" />
                        <el-button type="text" @click="videoToOtherLang">同步到其他语言</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> 取消 </el-button>
                <el-button type="primary" @click="handleSubmit()"> 确认 </el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="'同步到其他语言'"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            :before-close="closeCopyDialog"
            :close-on-click-modal="false"
        >
            <div>
                <el-checkbox :indeterminate="copyIsIndeterminate" v-model="copyCheckAll" @change="handleCopyCheckAllChange"
                    >全选
                </el-checkbox>
                <el-checkbox-group v-model="copyLangs" @change="handleCopyCheckedCitiesChange">
                    <el-checkbox v-for="(item, index) in copyLangList" :key="item.code" :label="item.code">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="dialog-footer">
                <el-button style="width: 20%" @click="closeCopyDialog">取 消</el-button>
                <el-button style="width: 20%" type="primary" @click="onCopySubmit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="'复制文章'"
            :visible.sync="dialogCopyVisible"
            :append-to-body="true"
            :before-close="closeCopyDialog"
            :close-on-click-modal="false"
            width="600px"
        >
            <el-form>
                <el-form-item label="将所选文章复制到">
                    <el-select v-model="copyType" placeholder="选择栏目">
                        <el-option label="企业新闻" value="News"></el-option>
                        <el-option label="博客" value="Blog"></el-option>
                        <el-option label="标签" value="Tag"></el-option>
                        <el-option label="品牌动态" value="Dynamics"></el-option>
                    </el-select>
                </el-form-item>
                <div style="margin-left: 190px; margin-top: 30px">
                    <el-button size="medium" type="primary" @click="copyArticle">确定</el-button>
                    <el-button size="medium" @click="closeCopyDialog">取消</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog
            :title="'编辑富文本源码'"
            :visible.sync="dialogEditContentVisible"
            :append-to-body="true"
            :before-close="closeCopyDialog"
            :close-on-click-modal="false"
            width="600px"
        >
            <el-form>
                <el-form-item>
                    <el-input type="textarea" v-model="contentCode" :rows="20" ></el-input>
                </el-form-item>
                <div style="margin-left: 190px; margin-top: 30px">
                    <el-button size="medium" type="primary" @click="editContentCode">确定</el-button>
                    <el-button size="medium" @click="closeCopyDialog">取消</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import translateEditor from '@/components/page/editor/translateEditor2.vue';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/components/common/imgUpload';
import X2JS from 'x2js'; //解析xml
import wangEditer from '@/components/common/WangEditer/index';
import videoUpload from '@/components/common/ossVideo/video';
import cache from '@/utils/cache';
import Config from '@/utils/config';
import scene from '../../utils/scene';
import dictionaryUtil from '../../utils/dictionaryUtil';

function initAddBean() {
    const addBean = {
        articleId: null,
        articleType: '',
        fileType: '',
        imageUrl: '',
        startTime: null,
        endTime: null,
        sort: 0,
        status: true,
        newed: false,
        recommend: false,
        countryCodes: [],
        langs: [],
        brandCodes: [],
        goodsClassifyId: null
    };
    return addBean;
}

export default {
    name: 'HomeArticleTable',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        translateEditor,
        ossUploader,
        imgUpload,
        wangEditer,
        videoUpload
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error('请选择时间范围'));
            }
        };
        return {
            // 类目下拉
            goodsClassifyDicts:[],
            goodsClassifyMap: new Map(),
            fileType: 'image',
            formTitle: '',
            old: initAddBean(),
            addArticle: initAddBean(),
            list: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            brandDicts: [],
            brand: null,
            imageShowStatus: false,
            videoShowStatus: false,
            aigoBrandStatus: false,
            noblezaBrandStatus: false,
            copyFileType: null,
            //表单选择的数据
            selectMessages: [],
            copyType:null,
            myTenantid:null,
            query: {
                page: 1,
                limit: 10,
                articleType: '',
                title: '',
                fileType: '',
                publishStatus: undefined,
                countryCode: [],
                brandCode: '',
                status: null,
                desc: true,
                newed: null,
                recommend: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,
            checkAll: false,
            ossVideoUrl: '',
            videoUploadPercent: 0,
            fileDataObj2: {},
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            isIndeterminate: true,
            valuePicker: [],
            countryLang: [],
            imageUrl: '',
            videoUrl: '',
            editorOption: {
                label: 'name',
                value: 'code'
            },
            //富文本内容的主要参数
            contentValue: '',
            contentIndex: 'zh',
            title: '',
            langsArr: [],
            langs: [],
            dialogFormVisible: false,
            dialogCopyVisible: false,
            dialogEditContentVisible:false,
            contentCode:'',
            copyCheckAll: false,
            copyIsIndeterminate: false,
            copyLangs: [],
            copyLangList: [],
            formRules: {
                articleType: [{ required: true, message: '请选择栏目', trigger: 'blur' }],
                fileType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                brandCodes: [{ required: true, message: '请选择品牌', trigger: 'blur' }],
                countryCodes: [{ required: true, message: '请选择国家', trigger: 'change' }],
                startTime: [{ validator: checkDateTime, required: true }]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '这个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '上一个月',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '今年',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    created(){
        this.initTenantId();
    },
    mounted() {
        this.doQueryList({ page: 1 });
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        initTenantId(){
            this.myTenantid = scene.getTenantId()
            console.log(this.myTenantid);
        },
        handleDisable(row, index) {
            // 函数需要一个返回值,true为可选,false为不可选择
            if (row.fileType == 'image') {
                return true;
            } else {
                return false;
            }
        },
        /**
         * 多选框处理
         */
        handleSelectionChange(val) {
            this.selectMessages = [];
            console.log(val);
            this.selectMessages = val;
        },
        /**
         * 复制文章
         */
        handleCopy() {
            this.copyType = null;
            this.dialogCopyVisible = true;
        },
        /**
         * 复制文章请求
         */
        copyArticle() {
            if (this.copyType == null) {
                this.$message({
                    message: '请选择复制类型',
                    type: 'warning'
                });
                return;
            }
            console.log(this.selectMessages);
            if (this.selectMessages.length == 0) {
                this.$message({
                    message: '请先选择数据',
                    type: 'warning'
                });
                return;
            }
            var reqList = [];
            for (var key in this.selectMessages) {
                this.selectMessages[key].createTime = moment(this.selectMessages[key].createTime).format('YYYY-MM-DD HH:mm:ss');
                if (this.selectMessages[key].updateTime != null) {
                    this.selectMessages[key].updateTime = moment(this.selectMessages[key].updateTime).format('YYYY-MM-DD HH:mm:ss');
                }
                this.selectMessages[key].articleId = null;
                reqList.push(this.selectMessages[key]);
            }
            this.$store.dispatch('copyArticle', { reqList: reqList, articleType: this.copyType }).then((res) => {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
                this.dialogCopyVisible = false;
                this.doQueryList({ page: 1 });
            });
        },
        /**
         * 类型为图文时赋值
         */
        initImage() {
            var cont = '';
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    cont = item.content;
                }
            }
            setTimeout(() => {
                this.$refs['wangEditer'].initData(cont);
            }, 200);
        },
        /**
         * 类型为视频时赋值
         */
        initVideo() {
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.videoUrl = item.content;
                    setTimeout(() => {
                        this.$refs['videoUpload'].initData(this.videoUrl);
                        this.$refs['videoUpload'].videoUploadPercent = 0;
                    }, 200);
                }
            }
        },
        /**
         * 重置多语言
         */
        initLangsArr() {
            for (var item of this.langs) {
                let param = {
                    lang: item.code,
                    content: '',
                    title: ''
                };
                this.langsArr.push(param);
            }
        },
        /**
         * 栏目转变时，视频专区只能传视频
         */
        articleChange() {
            console.log(this.addArticle.articleType);
            this.aigoBrandStatus = false;
            this.noblezaBrandStatus = false;
            this.addArticle.goodsClassifyId = ''
            //视频专区只能传视频
            if (this.addArticle.articleType == 'Video') {
                this.addArticle.fileType = 'video';
                this.imageShowStatus = true;
                this.videoShowStatus = false;
                this.fileType = 'video';
                // 更新类目下拉
                this.getChooseHasGoodsTree(this.brand)
            }
            //企业新闻,博客,标签只能传图文
            else if (
                this.addArticle.articleType == 'News' ||
                this.addArticle.articleType == 'Blog' ||
                this.addArticle.articleType == 'Tag'
            ) {
                this.addArticle.fileType = 'image';
                this.imageShowStatus = false;
                this.videoShowStatus = true;
            }
            //Aigosmart只能选图文
            else if (this.addArticle.articleType == 'Aigosmart') {
                this.addArticle.fileType = 'image';
                this.brand = 'AigoStar';
                this.imageShowStatus = false;
                this.videoShowStatus = true;
                this.noblezaBrandStatus = true;
            } else {
                this.addArticle.fileType = '';
                this.imageShowStatus = false;
                this.videoShowStatus = false;
                this.aigoBrandStatus = false;
                this.noblezaBrandStatus = false;
                this.fileType = '';
            }
        },
        handleContentSelect(key, keyPath) {
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml();
            }

            let titleResult = '';
            if (this.title != undefined) {
                titleResult = this.title;
            }

            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].title = titleResult;
                    this.langsArr[index].content = result;
                }
            }

            //更新富文本内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.$refs['wangEditer'].initData(item.content);
                }
            }
        },
        handleVideoSelect(key, keyPath) {
            //重置视频上传组件
            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].title = this.title;
                }
            }

            //更新视频内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.videoUrl = item.content == undefined ? '' : item.content;
                    this.$refs['videoUpload'].initData(this.videoUrl);
                    this.$refs['videoUpload'].videoUploadPercent = 0;
                }
            }
        },
        /**
         * 上传路径
         */
        changeUrl(data) {
            this.addArticle.imageUrl = data;
            console.log(data);
        },
        changeVideoUrl(data) {
            console.log(data);
            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].content = data;
                    this.videoUrl = data;
                }
            }
            console.log(this.langsArr);
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.listLoading = true;
            this.$store
                .dispatch('getArticlePage', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteArticle', row.articleId).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle = '编辑';
            this.old = row;
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addArticle[key] = this.old[key];
            }
            //品牌赋值
            this.brand = this.addArticle.brandCodes[0];
        
            this.articleChange();
            this.addArticle.fileType = row.fileType;
            this.addArticle.goodsClassifyId = row.goodsClassifyId
            
            this.imageUrl = this.addArticle.imageUrl;
            this.valuePicker[0] = moment(row.startTime).format('yyyy-MM-DD HH:mm:ss');
            this.valuePicker[1] = moment(row.endTime).format('yyyy-MM-DD HH:mm:ss');
            this.addVisible = true;
            //初始化多语言
            for (var item of this.addArticle.langs) {
                for (const key in this.langsArr) {
                    if (item.lang == this.langsArr[key].lang) {
                        this.langsArr[key].title = item.title;
                        this.langsArr[key].content = item.content;
                    }
                }
            }
            console.log(this.langsArr);
            //赋值多语言信息列表
            if (this.addArticle.fileType == 'image') {
                this.initImage();
            }
            if (this.addArticle.fileType == 'video') {
                this.initVideo();
            }

            //多选框
            this.handleCheckedCitiesChange(this.addArticle.countryCodes);
            // if(this.addArticle.articleType == 'Video'){
            //     setTimeout(() => {
            //         this.getChooseHasGoodsTree(row.brandCodes[0])
            //     }, 200);  
            // }
            
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.fileType = '';
            this.addArticle = initAddBean();
            this.valuePicker = [];
            this.addArticle.langs = [];
            this.contentIndex = 'zh';
            this.langsArr = [];
            this.imageShowStatus = false;
            this.videoShowStatus = false;
            this.initLangsArr();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.fileType = '';
            this.imageShowStatus = false;
            this.videoShowStatus = false;
            this.isIndeterminate = false;
            this.valuePicker = [];
            this.checkAll = false;
            this.addArticle = initAddBean();
            this.noblezaBrandStatus = false;
            this.aigoBrandStatus = false;
            this.addArticle.langs = [];
            this.imageUrl = '';
            this.contentIndex = 'zh';
            this.videoUrl = '';
            this.title = '';
            this.contentValue = '';
            if(this.myTenantid == 1001002001){
                this.brand = 'Unico'
            }
            if(this.myTenantid == 1001001000){
                this.brand = 'AigoStar';
            }
            
            this.langsArr = [];
            this.initLangsArr();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = '新增';
            this.resetForm();
            this.addVisible = true;
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            //上传图片校验
            if (this.addArticle.imageUrl == '' || this.addArticle.imageUrl == null) {
                this.$message({
                    showClose: false,
                    message: '图片必传',
                    type: 'warning'
                });
                return;
            }

            if (this.addArticle.fileType == 'image') {
                //取富文本值
                let result = this.$refs['wangEditer'].getHtml();

                //当前选项多语言信息赋值
                for (const key in this.langsArr) {
                    if (this.langsArr[key].lang == this.contentIndex) {
                        this.langsArr[key].title = this.title;
                        this.langsArr[key].content = result;
                    }
                }
            }
            if (this.addArticle.fileType == 'video') {
                //当前选项多语言信息赋值
                for (const key in this.langsArr) {
                    if (this.langsArr[key].lang == this.contentIndex) {
                        this.langsArr[key].title = this.title;
                        this.langsArr[key].content = this.videoUrl;
                    }
                }
            }

            this.addArticle.langs = this.langsArr;

            this.addArticle.startTime = moment(this.valuePicker[0]).unix() * 1000;
            this.addArticle.endTime = moment(this.valuePicker[1]).unix() * 1000;

            //多语言校验
            var countryLangs = [];
            for (var item of this.addArticle.countryCodes) {
                for (var langItem of this.countryLang) {
                    if (item == langItem.code && item != 'OT') {
                        countryLangs.push(langItem.name);
                    }
                }
            }
            this.addArticle.langs.filter((x) => x.lang);
            for (var item of this.addArticle.langs) {
                if (countryLangs.includes(item.lang) && item.title == '') {
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message: '所选国家标题必填',
                        type: 'warning'
                    });
                    return;
                }
                if (countryLangs.includes(item.lang) && item.content == '') {
                    console.log(countryLangs);
                    countryLangs = [];
                    console.log(item.content);

                    this.$message({
                        showClose: false,
                        message: '所选国家内容必填',
                        type: 'warning'
                    });
                    return;
                }
            }

            //添加品牌
            this.addArticle.brandCodes = [];
            this.addArticle.brandCodes.push(this.brand);

            if (this.formTitle == '新增') {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('getHomeArticle', this.addArticle).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('homeArticleEdit', this.addArticle).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '编辑失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        /**
         * 修改排序
         */
        putSortArticle(row) {
            const str = '^(\\d+)\\.(\\d{0}).*$';
            const reg = new RegExp(str);
            this.$store
                .dispatch('putSortArticle', {
                    id: row.articleId,
                    sort: row.sort ? row.sort : 0
                })
                .then((res) => {
                    this.doQueryList({ page: 1 });
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                });
        },
        //自定义label
        renderSortHeader() {
            return (
                <div>
                    <span class="sort-span" on-click={() => this.handleSort()}>
                        排序 <span class="el-icon-sort"></span>
                    </span>
                </div>
            );
        },
        //排序
        handleSort() {
            this.query.desc = !this.query.desc;
            setTimeout(() => {
                this.doQueryList({ page: 1 });
            });
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },
        // getDictItems() {
        //         var _this = this;
        //         this.$store.dispatch('getDicItems', { tag: 'countrys' }).then(res => {
        //             if (res != null) _this.countrysDicts = res;
        //         });
        // },
        //国家全选
        handleCheckAllChange(val) {
            if (val) {
                this.addArticle.countryCodes = [];
                for (var item of this.countrysDicts) {
                    this.addArticle.countryCodes.push(item.code);
                }
            } else {
                this.addArticle.countryCodes = [];
            }

            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.countrysDicts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.countrysDicts.length;
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
                this.initTableGoodsTree();
            });
            this.$store.dispatch('getDicItems', { tag: 'country_ot' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
            
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
                _this.copyLangList = res;
            });
        },
        cleanQuery() {
            this.query.title = '';
            this.query.articleType = '';
            this.query.countryCode = [];
            this.query.fileType = '';
            this.query.brandCode = '';
            this.query.status = null;
            this.query.newed = null;
            this.query.recommend = null;
            this.query.publishStatus = undefined;
            this.doQueryList({ page: 1 });
        },

        fileTypeChange() {
            this.fileType = this.addArticle.fileType;
        },

        /**
         * 视频同步到其他语言
         */
        videoToOtherLang() {
            this.$confirm('确认将视频同步到其他语言吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    for (const key in this.langsArr) {
                        this.langsArr[key].content = this.videoUrl;
                    }
                    this.$message({
                        type: 'success',
                        message: '同步成功!'
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        /**
         * 编辑富文本
         */
        openEdit(){
            this.dialogEditContentVisible = true;
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml();
            }
            this.contentCode = result;
            console.log(this.contentCode);
        },
        /**
         * 提交编辑富文本信息
         */
        editContentCode(){
            this.$refs['wangEditer'].setHtml(this.contentCode);
            this.dialogEditContentVisible = false;
        },
        /**
         * 内容同步到其他语言
         */
        contentToOtherLang() {
            this.dialogFormVisible = true;
            this.copyLangs = this.copyLangList.map((value) => value.code);
            this.copyCheckAll = true;
        },
        closeCopyDialog() {
            this.dialogFormVisible = false;
            this.dialogCopyVisible = false;
            this.dialogEditContentVisible = false;
            this.copyLangs = [];
            this.copyIsIndeterminate = false;
        },
        handleCopyCheckAllChange(val) {
            this.copyLangs = val ? this.copyLangList.map((value) => value.code) : [];
            this.copyIsIndeterminate = false;
        },
        handleCopyCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.copyCheckAll = checkedCount === this.copyLangList.length;
            this.copyIsIndeterminate = checkedCount > 0 && checkedCount < this.copyLangList.length;
        },
        onCopySubmit() {
            if (this.copyLangs.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择要同步的语言!'
                });
                return;
            }
            this.dialogFormVisible = false;
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml();
            }

            let titleResult = '';
            if (this.title != undefined) {
                titleResult = this.title;
            }
            this.copyLangs.forEach((item) => {
                this.langsArr.forEach((item2) => {
                    if (item === item2.lang) {
                        item2.title = titleResult;
                        item2.content = result;
                    }
                });
            });
        },
        // 品牌变化
        brandChange(val){          
            this.addArticle.goodsClassifyId =null
            this.getChooseHasGoodsTree(val)   
        },
        // 初始化 列表类目
        initTableGoodsTree(){
            this.brandDicts.forEach(item=>{
                this.getChooseHasGoodsTree(item.code)
            });
        },
        // 获取特定选择的类目
        getChooseHasGoodsTree(val) {
            let param = {
                brandCode: val,
                langCode: 'zh'
            }
            this.$store.dispatch('getChooseHasGoodsTree', param).then((res) => {
                console.log('getChooseHasGoodsTree',res)
                this.goodsClassifyDicts = res  
                this.goodsClassifyMap.set(val,res)
    
            })
        },
        getDictValueByGoodsClassifyId(row){
            // console.log(row)
            if(row.goodsClassifyId == null){
                return '';
            }
            if(row.articleType == 'Video'){
                if(row.brandCodes!= null && row.brandCodes.length>0){
                    let brandCode = row.brandCodes[0]
                    let goodsClassifyList =  this.goodsClassifyMap.get(brandCode);

                    if (goodsClassifyList == undefined || goodsClassifyList == null || goodsClassifyList.length == 0) {
                        return '';
                    }
                    var code = goodsClassifyList.find(x => x.goodsClassifyId == row.goodsClassifyId);
                    if (code) {
                        return code.title;
                    }
                }
            
            }
            return ''
        },
        changeStatus(row) {
            console.log(row);
            this.$store.dispatch('homeArticleEdit', row).then((data) => {
                this.doQueryList({ page: 1 });
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
            });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
