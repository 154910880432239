<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        v-model="query.name"
                        placeholder="职位名称"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                        clearable
                    />
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.jobType"
                        clearable
                        :codeType="'job_type'"
                        placeholder="职位类型"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCodes"
                        :multiple="true"
                        placeholder="国家"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        查询
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" icon="el-icon-edit" @click="handleCreate">
                添加
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" type="index" align="center" width="50px"> </el-table-column>
            <el-table-column label="职位类型" prop="jobType" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.jobType" :codeType="'job_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column label="职位名称" prop="name" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="办公地点" prop="joinUsCountrys" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.joinUsCountrys" :key="index">
                        <dataText v-model="item.countryCode" :codeType="'countrys'" style="display: inline"></dataText>
                        <span v-if="scope.row.joinUsCountrys.length != 1 && index != scope.row.joinUsCountrys.length - 1">、</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="创建时间" prop="createTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="更新时间" prop="updateTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addJoin"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item prop="jobType" label="职位类型">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addJoin.jobType"
                        clearable
                        :codeType="'job_type'"
                        placeholder="职位类型"
                    ></dataSelect>
                </el-form-item>
                <el-form-item :required="true" label="办公国家">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="countryCodes"
                        clearable
                        multiple
                        :codeType="'countrys'"
                        placeholder="国家"
                    ></dataSelect>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="addJoin.sort" :min="0" style="width: 100%"></el-input-number>
                </el-form-item>
                <!-- </div> -->

                <el-form-item label="多语言配置" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>职位名称：</span>
                        <el-input style="margin-top: 20px; width: 600px" v-model="name" placeholder="输入名称" label="职位名称"></el-input>
                    </div>
                    <div>
                        <span>办公地点：</span>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10 }"
                            style="margin-top: 20px; width: 600px"
                            v-model="location"
                            placeholder="输入办公地点"
                            label="办公地点"
                        ></el-input>
                    </div>
                    <div>
                        <span>职位描述：</span>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10 }"
                            style="margin-top: 20px; width: 600px"
                            v-model="describe"
                            placeholder="输入职位描述"
                            label="职位描述"
                        ></el-input>
                    </div>
                    <div>
                        <span>联系我们：</span>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10 }"
                            style="margin-top: 20px; width: 600px"
                            v-model="contactUs"
                            placeholder="输入联系我们"
                            label="联系我们"
                        ></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> 取消 </el-button>
                <el-button type="primary" @click="handleSubmit()"> 确认 </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/components/common/imgUpload/imgUpload';
import X2JS from 'x2js'; //解析xml

function initAddBean() {
    const addBean = {
        joinUsId: null,
        jobType: null,
        sort: 0,
        joinUsLangs: [],
        joinUsCountrys: []
    };
    return addBean;
}

export default {
    name: 'Strength',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        imgUpload
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error('请选择时间范围'));
            }
        };
        return {
            formTitle: '',
            old: initAddBean(),
            addJoin: initAddBean(),
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            query: {
                page: 1,
                limit: 10,
                name: null,
                jobType: null,
                countryCodes:[],
            },
            countryCodes: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,

            countryLang: [],
            //富文本内容的主要参数
            contentIndex: 'zh',
            langsArr: [],
            describe: '',
            location: '',
            name: '',
            contactUs: '',
            //字典取的语言
            langs: [],
            formRules: {
                jobType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                // countryCodes: [{ required: true, message: '请选择国家', trigger: 'change' }]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        init() {
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.name = item.name;
                    this.location = item.location;
                    this.describe = item.describe;
                    this.contactUs = item.contactUs;
                }
            }
        },
        /**
         * 重置多语言
         */
        initLangsArr() {
            for (var item of this.langs) {
                let param = {
                    lang: item.code,
                    name: '',
                    location: '',
                    describe: '',
                    contactUs: ''
                };
                this.langsArr.push(param);
            }
        },
        /**
         * 富文本取值、回显
         */
        handleContentSelect(key, keyPath) {
            //赋值
            for (const key in this.langsArr) {
                if (this.langsArr[key].lang == this.contentIndex) {
                    this.langsArr[key].name = this.name!=undefined ? this.name.slice(0,199) : '';
                    this.langsArr[key].location = this.location != undefined ? this.location.slice(0,9999) : '';
                    this.langsArr[key].describe = this.describe != undefined ? this.describe.slice(0,9999) : '';
                    this.langsArr[key].contactUs = this.contactUs != undefined ? this.contactUs.slice(0,9999) : '';
                }
            }

            //更新富文本内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.name = item.name;
                    this.describe = item.describe;
                    this.location = item.location;
                    this.contactUs = item.contactUs;
                }
            }
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            console.log(this.query);
            this.$store.dispatch('getJoinPage', this.query).then((res) => {
                this.list = res;
            });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteJoin', { id: row.joinUsId }).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle = '编辑';
            this.old = row;
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addJoin[key] = this.old[key];
            }
            this.addVisible = true;
            for (var item of this.addJoin.joinUsLangs) {
                for (const key in this.langsArr) {
                    if (item.lang == this.langsArr[key].lang) {
                        this.langsArr[key].name = item.name;
                        this.langsArr[key].location = item.location;
                        this.langsArr[key].describe = item.describe;
                        this.langsArr[key].contactUs = item.contactUs;
                    }
                }
            }
            //赋值多语言信息列表
            this.init();
            //赋值国家
            let arr5 = [];
            for (const key in this.addJoin.joinUsCountrys) {
                arr5.push(this.addJoin.joinUsCountrys[key].countryCode);
            }
            this.countryCodes = arr5;
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.addJoin = initAddBean();
            this.name = '';
            this.location = '';
            this.describe = '';
            this.contactUs = '';
            this.contentIndex = 'zh';
            this.langsArr = [];
            this.countryCodes = [];
            this.initLangsArr();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.addJoin = initAddBean();
            this.contentIndex = 'zh';
            this.name = '';
            this.location = '';
            this.describe = '';
            this.contactUs = '';
            this.countryCodes = [];
            this.langsArr = [];
            this.initLangsArr();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = '新增';
            this.resetForm();
            this.addVisible = true;
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            if (this.countryCodes.length == 0) {
                this.$message({
                    showClose: false,
                    message: '办公国家必选',
                    type: 'warning'
                });
                return;
            }

            //当前选项多语言信息赋值
            for (const key in this.langsArr) {
                if (this.langsArr[key].lang == this.contentIndex) {
                    this.langsArr[key].name = this.name!=undefined ? this.name.slice(0,199) : '';
                    this.langsArr[key].location = this.location!=undefined ? this.location.slice(0,9999) : '';
                    this.langsArr[key].describe = this.describe!=undefined ? this.describe.slice(0,9999) : '';
                    this.langsArr[key].contactUs = this.contactUs!=undefined ? this.contactUs.slice(0,9999) : '';
                }
            }
            console.log(this.langsArr);

            //多国家配置
            let arr3 = [];
            for (const index in this.countryCodes) {
                let param2 = {
                    countryCode: this.countryCodes[index]
                };
                arr3.push(param2);
            }
            this.addJoin.joinUsCountrys = arr3;

            //对应国家语言
            var countryLangs = [];
            for (var item of this.addJoin.joinUsCountrys) {
                for (var langItem of this.countryLang) {
                    if (item.countryCode == langItem.code) {
                        countryLangs.push(langItem.name);
                    }
                }
            }

            this.addJoin.joinUsLangs = this.langsArr;
            console.log(this.addJoin.joinUsLangs);

            for (var item of this.addJoin.joinUsLangs) {
                //所选国家内容必填
                if (countryLangs.includes(item.lang)) {
                    if (item.name == '' || item.describe == '' || item.location == '' || item.contactUs == '') {
                        this.$message({
                            showClose: false,
                            message: '所选国家内容必填',
                            type: 'warning'
                        });
                        return;
                    }
                }
            }

            console.log(this.addJoin);

            if (this.formTitle == '新增') {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addJoin', this.addJoin).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.addJoin);
                        this.$store.dispatch('editJoin', this.addJoin).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '编辑失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query.name = null;
            this.query.jobType = null;
            this.query.countryCodes = [];
            this.doQueryList({ page: 1 });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
/* .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
} */
</style>
