<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item prop="datePeriod" label="">
                    <el-date-picker
                        ref="picker"
                        v-model="datePeriod"
                        unlink-panels
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="query.searchKey"
                        placeholder="姓/名/联系邮箱/联系电话"
                        style="width: 180px"
                        class="filter-item"
                        clearable
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.country"
                        :multiple="false"
                        :clearable="true"
                        placeholder="国家"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCodes"
                        :multiple="true"
                        placeholder="意向经销区域"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                        <el-option label="其他" value="OT"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.brandCode"
                        :multiple="true"
                        placeholder="意向经销品牌"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        placeholder="是否已推送B2B后台"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="是" value="true"></el-option>
                        <el-option label="否" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.autoStatus"
                        placeholder="是否自动推送"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="是" value="true"></el-option>
                        <el-option label="否" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.submitType"
                        clearable
                        :codeType="'submit_type'"
                        placeholder="提交页面"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="query.remark"
                        placeholder="备注"
                        style="width: 180px"
                        class="filter-item"
                        clearable
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>

                <div>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> 查询 </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="handleExport"
                        style="margin-left: 10px"
                        size="small"
                    >
                        导出
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="handleRemark"
                        style="margin-left: 10px"
                        size="small"
                    >
                        备注
                    </el-button>
                    <el-button class="filter-item" type="primary" :disabled="this.selectMessages.length < 1"
                        @click="handleBatchReplies" style="margin-left: 10px" size="small"
                    >
                        批量回复 
                        <span v-if="this.selectMessages.length > 0">( {{this.selectMessages.length}})</span>
                    </el-button>
                </div>
            </el-form>
        </div>
        <div>
            <!-- <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="primary" icon="el-icon-edit" @click="handlePush">
                推送
            </el-button> -->
           <!--  <span style="margin: 10px 0px 10px 20px; color: gray">是否自动推送至B2B后台:</span>
            <el-switch v-model="autoStatus" @change="changeAutoStatus()" style="margin-left: 10px"></el-switch> -->
        </div>

        <el-table
            ref="dataTable"
            v-loading="listLoading"
            :data="list.list"
            border
            stripe
            row-key="userCommentId"
            style="width: 100%; margin-top: 20px"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" :reserve-selection="true" :selectable="handleDisable"></el-table-column>
            <el-table-column label="官网" prop="brandCode" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="国家" prop="countryCode" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="姓" prop="lastName" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="名" prop="firstName" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="联系邮箱" prop="email" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="联系电话" prop="phoneNumber" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="意向经销区域" prop="countryStr" align="center" show-overflow-tooltip>
                <!-- <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.userCommentCountrys" :key="index">
                        <dataText v-model="item.countryCode" :codeType="'country_all'" style="display: inline"></dataText>
                        <span v-if="scope.row.userCommentCountrys.length != 1 && index != scope.row.userCommentCountrys.length - 1">/</span>
                    </span>
                </template> -->
            </el-table-column>
            <el-table-column label="意向经销品牌" prop="userCommentBrands" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.userCommentBrands" :key="index">
                        {{ item.brandCode }}
                        <span v-if="scope.row.userCommentBrands.length != 1 && index != scope.row.userCommentBrands.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="业务类型" prop="businessStr" align="center" width="300px">
                <template slot-scope="scope">
                    <span class="business">{{ scope.row.businessStr}}</span>
                </template>
            </el-table-column>
            <el-table-column label="留言" prop="content" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    {{ row.content.slice(0, 200) }}
                </template>
            </el-table-column>
            <el-table-column label="提交时间" prop="createTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>

            <el-table-column prop="status" label="是否已推送B2B后台" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == true">已推送</span>
                    <span v-if="scope.row.status == false">未推送</span>
                </template>
            </el-table-column>
            <el-table-column prop="autoStatus" label="是否自动推送" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.autoStatus == true">是</span>
                    <span v-if="scope.row.autoStatus == false">否</span>
                </template>
            </el-table-column>
            <el-table-column prop="submitType" label="提交页面" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <dataText v-model="scope.row.submitType" :codeType="'submit_type'" style="display: inline"></dataText>
                </template>
            </el-table-column>
            <el-table-column label="提交页面URL" prop="submitUrl" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <!-- <el-button type="primary" size="mini" @click="pushMessage(row)" class="table-inner-button" v-if="row.status == true"
                        >推送信息</el-button
                    > -->
                    <el-button type="primary" size="mini" @click="handleReply(row)" class="table-inner-button" >回复</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <el-dialog title="推送信息" :visible.sync="messageVisible" v-if="messageVisible" width="1200px" :close-on-click-modal="false">
            <el-table :data="message.list" border stripe style="width: 100%; margin-top: 20px" size="small">
                <el-table-column type="index" show-overflow-tooltip label="序号" align="center"> </el-table-column>
                <el-table-column prop="workerName" label="推送业务员" show-overflow-tooltip align="center"> </el-table-column>
                <el-table-column prop="email" label="推送邮箱" show-overflow-tooltip align="center"> </el-table-column>
                <el-table-column label="推送时间" prop="pushTime" align="center" show-overflow-tooltip :formatter="formatDate">
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                    style="margin-top: 10px"
                    background
                    @current-change="handleMessageCurrentChange"
                    :current-page="messageQuery.page"
                    :page-size="messageQuery.size"
                    layout="total, prev, pager, next, jumper"
                    :total="message.total"
                ></el-pagination>
            </div>
        </el-dialog>
        <el-dialog title="批量备注" center :visible.sync="remarkVisible" v-if="remarkVisible" width="500px">
            <el-input type="textarea" :rows="5" placeholder="输入备注" v-model="remark" :maxlength="500"> </el-input>
            <div>
                <el-button
                    class="filter-item"
                    @click="handleClose"
                    style="margin-left: 60px; margin-top: 30px; width: 150px"
                    size="medium "
                >
                    取消</el-button
                >

                <el-button
                    class="filter-item"
                    type="primary"
                    @click="remarkComment"
                    style="margin-left: 30px; margin-top: 30px; width: 150px"
                    size="medium "
                >
                    确定</el-button
                >
            </div>
        </el-dialog>


        <el-dialog title="邮件回复" :visible.sync="dialogEmailReplyVisible" :close-on-click-modal="false"
        >
            <div v-if="dialogEmailReplyVisible">
                <emailReply :userCommentList="userCommentList" :replysFlag="replysFlag"  @closeEmailReplyDialog="closeEmailReplyDialog" />
            </div>

        </el-dialog>


    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/components/common/imgUpload/imgUpload';
import X2JS from 'x2js'; //解析xml

import emailReply from './components/EmailReply.vue';

export default {
    name: 'UserComment',
    components: {
        emailReply,
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        imgUpload
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            message: [],
            //表单选择的数据
            selectMessages: [],

            //多选
            brandCodes: [],
            countryCodes: [],

            datePeriod:[],

            query: {
                page: 1,
                size: 10,
                searchKey: '',
                country: null,
                status: null,
                brandCode: [],
                countryCodes: [],
                autoStatus: null,
                submitType: null,
                remark: null,
            },
            messageQuery: {
                page: 1,
                size: 10,
                id: null
            },
            userCommentId: null,
            countryLang: [],
            messageVisible: false,
            remarkVisible: false,
            //自动推送开关
            autoStatus: false,
            // 回复
            dialogEmailReplyVisible:false,
            userCommentList:[],
            //  是否批量回复
            replysFlag: false,
            //备注
            remark: '',
            pickerOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '这个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '上一个月',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '今年',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
        this.getAutoStatus();
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        // method
        // 处理表格数据,已推送的默认不能勾选
        // 有两个参数返回,表格的每一行对象和当前索引
        handleDisable(row, index) {
            // 函数需要一个返回值,true为可选,false为不可选择
            // if (row.status === true) {
            //     return false;
            // } else {
            //     return true;
            // }
            return true;
        },
        /**
         * 多选框处理
         */
        handleSelectionChange(val) {
            this.selectMessages = [];
            console.log(val);
            this.selectMessages = val;
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.datePeriod != null && this.datePeriod.length > 0) {
                this.query.beginTime = moment(this.datePeriod[0]).format('yyyy-MM-DD 00:00:00');
                this.query.endTime = moment(this.datePeriod[1]).format('yyyy-MM-DD 23:59:59');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }
            this.doQueryList({ page: 1 });
            console.log("test");
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.$store.dispatch('getUserCommentPage', this.query).then((res) => {
                this.list = res;
            });
        },

        /**
         * 处理推送信息
         */
        handlePush() {
            console.log(this.selectMessages);
            if (this.selectMessages.length == 0) {
                this.$message({
                    message: '请先选择数据',
                    type: 'warning'
                });
                return;
            }
            var userCommentDtos = [];
            for (var key in this.selectMessages) {
                if(this.selectMessages[key].status){
                    continue;
                }
                this.selectMessages[key].createTime = moment(this.selectMessages[key].createTime).format('YYYY-MM-DD HH:mm:ss');
                if (this.selectMessages[key].updateTime != null) {
                    this.selectMessages[key].updateTime = moment(this.selectMessages[key].updateTime).format('YYYY-MM-DD HH:mm:ss');
                }
                
                userCommentDtos.push(this.selectMessages[key]);
            }
            console.log(userCommentDtos);
            if (userCommentDtos.length == 0) {
                this.$message({
                    message: '请先选择未推送数据',
                    type: 'warning'
                });
                return;
            }

            this.$confirm('确认推送吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('pushMessage', userCommentDtos).then((res) => {
                        this.$message({
                            message: '推送成功',
                            type: 'success'
                        });
                        this.doQueryList({ page: 1 });
                    });
                })
                .catch(() => {});
        },
        /**
         * 备注
         */
        handleRemark() {
            console.log(this.selectMessages);
            if (this.selectMessages.length == 0) {
                this.$message({
                    message: '请先选择数据',
                    type: 'warning'
                });
                return;
            }
            this.remarkVisible = true;
            this.remark = '';
        },
        handleClose() {
            this.remarkVisible = false;
        },
        remarkComment() {
            if(!this.remark || this.remark == ''){
                this.$message({
                    message: '请输入备注',
                    type: 'warning'
                });
                return;
            }
            var userCommentDtos = [];
            for (var key in this.selectMessages) {
                this.selectMessages[key].createTime = moment(this.selectMessages[key].createTime).format('YYYY-MM-DD HH:mm:ss');
                if (this.selectMessages[key].updateTime != null) {
                    this.selectMessages[key].updateTime = moment(this.selectMessages[key].updateTime).format('YYYY-MM-DD HH:mm:ss');
                }
                userCommentDtos.push(this.selectMessages[key]);
            }
            console.log(userCommentDtos);
            var userRemarkDto={
                userCommentDtoList:userCommentDtos,
                remark:this.remark
            }
            this.$store.dispatch('remarkUserComment', userRemarkDto).then((res) => {
                this.$message({
                    message: '备注成功',
                    type: 'success'
                });
                this.remarkVisible = false;
                this.$refs.dataTable.clearSelection()
                this.selectMessages = [];
                this.doQueryList({ page: 1 });
            });
        },

        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = '用户留言数据.xlsx';
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('exportExcel', this.query).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '导出成功'
                        });
                    });
                })
                .catch(() => {});
        },

        handleMessageCurrentChange(val) {
            this.messageQuery.page = val;
            this.doQueryMessage({ page: val, id: this.userCommentId });
        },

        //分页查推送信息
        doQueryMessage({ page, size, id }) {
            if (page) {
                this.messageQuery.page = page;
            }
            if (size) {
                this.messageQuery.size = size;
            }
            if (id) {
                this.messageQuery.id = id;
            }
            this.$store.dispatch('getMessageById', this.messageQuery).then((res) => {
                this.message = res;
                console.log(this.message);
                this.messageVisible = true;
            });
        },

        /**
         * 显示推送信息
         */
        pushMessage(row) {
            this.doQueryMessage({ page: 1, id: row.userCommentId });
            this.userCommentId = row.userCommentId;
        },

        /**
         * 获取自动推送状态
         */
        getAutoStatus() {
            this.$store.dispatch('getAutoStatus').then((res) => {
                this.autoStatus = res;
            });
        },

        /**
         * 更改自动推送状态
         */
        changeAutoStatus() {
            this.$confirm('确认更改推送状态?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('changeAutoStatus').then((res) => {
                        this.getAutoStatus();
                        this.$message({
                            message: '更改成功',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {})
                .finally(() => {
                    this.getAutoStatus();
                });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        // 批量回复
        handleBatchReplies(){   
            this.replysFlag = true;
            this.dialogEmailReplyVisible = true;
            this.userCommentList = JSON.parse(JSON.stringify(this.selectMessages));
        },
        // 回复
        handleReply(row){
            this.replysFlag = false;
            this.dialogEmailReplyVisible = true;
            this.userCommentList = [];
            this.userCommentList.push(row);
        },
        // 回复 关闭
        closeEmailReplyDialog(status,replysFlag){
            console.log('closeEmailReplyDialog',status,replysFlag)
            this.replysFlag = false;
            this.dialogEmailReplyVisible = false;
            this.userCommentList = [];
            // 批量回复
            if(status && replysFlag){
                this.$refs.dataTable.clearSelection();
                this.selectMessages = [];
            }
            // 批量回复 或 回复
            if(status){
                this.doQueryList({page: this.query.page});
            }
        },
        cleanQuery() {
            this.query.searchKey = '';
            this.query.status = null;
            this.query.country = null;
            this.query.brandCode = [];
            this.query.countryCodes = [];
            this.query.autoStatus = null;
            this.query.submitType = null;
            this.query.remark = null;
            this.doQueryList({ page: 1 });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.business{
  white-space: pre-line;
}
/* .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
} */
</style>
