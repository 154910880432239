var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      placeholder: "标题",
                      oninput: "value=value.replace(/[%]/g,'')",
                      clearable: ""
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.title,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "title", $$v)
                      },
                      expression: "query.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      codeType: "article_type",
                      placeholder: "栏目"
                    },
                    model: {
                      value: _vm.query.articleType,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "articleType", $$v)
                      },
                      expression: "query.articleType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { multiple: true, placeholder: "展示国家" },
                      model: {
                        value: _vm.query.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCode", $$v)
                        },
                        expression: "query.countryCode"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { placeholder: "类型", clearable: "" },
                      model: {
                        value: _vm.query.fileType,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "fileType", $$v)
                        },
                        expression: "query.fileType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "图文", value: "image" }
                      }),
                      _c("el-option", {
                        attrs: { label: "视频", value: "video" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        multiple: false,
                        placeholder: "品牌",
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.brandCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "brandCode", $$v)
                        },
                        expression: "query.brandCode"
                      }
                    },
                    _vm._l(_vm.brandDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: { label: cc.name, value: cc.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { placeholder: "状态", clearable: "" },
                      model: {
                        value: _vm.query.publishStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "publishStatus", $$v)
                        },
                        expression: "query.publishStatus"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "即将发布", value: "0" }
                      }),
                      _c("el-option", {
                        attrs: { label: "发布中", value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: "已结束", value: "2" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { placeholder: "是否启用", clearable: "" },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { placeholder: "是否推荐", clearable: "" },
                      model: {
                        value: _vm.query.recommend,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "recommend", $$v)
                        },
                        expression: "query.recommend"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: "是否最新", clearable: "" },
                      model: {
                        value: _vm.query.newed,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "newed", $$v)
                        },
                        expression: "query.newed"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [
                      _vm._v(
                        "\n                        查询\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                        重置\n                    "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { margin: "10px 0px 0px 0px" },
                      attrs: { type: "success", icon: "el-icon-edit" },
                      on: { click: _vm.handleCreate }
                    },
                    [
                      _vm._v(
                        "\n                        添加\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { margin: "10px 0px 0px 10px" },
                      attrs: { type: "success", icon: "el-icon-edit" },
                      on: { click: _vm.handleCopy }
                    },
                    [
                      _vm._v(
                        "\n                        复制文章\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list.list, border: "", stripe: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", selectable: _vm.handleDisable }
          }),
          _c("el-table-column", {
            attrs: {
              label: "栏目",
              prop: "articleType",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "article_type" },
                      model: {
                        value: scope.row.articleType,
                        callback: function($$v) {
                          _vm.$set(scope.row, "articleType", $$v)
                        },
                        expression: "scope.row.articleType"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCodes",
              label: "展示国家",
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.countryCodes, function(item, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item) +
                          "\n                        "
                      ),
                      scope.row.countryCodes.length != 1 &&
                      index != scope.row.countryCodes.length - 1
                        ? _c("span", [_vm._v("/")])
                        : _vm._e()
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "标题",
              prop: "title",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "类型",
              prop: "fileType",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.fileType == "image"
                      ? _c("span", [_vm._v(_vm._s("图文"))])
                      : _vm._e(),
                    row.fileType == "video"
                      ? _c("span", [_vm._v(_vm._s("视频"))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              prop: "imageUrl",
              label: "封面",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.imageUrl != ""
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src:
                              _vm.ossHost +
                              scope.row.imageUrl +
                              "?x-oss-process=style/list_s",
                            fit: "fill"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandCodes",
              label: "品牌",
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.brandCodes, function(item, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item) +
                          "\n                        "
                      ),
                      scope.row.brandCodes.length != 1 &&
                      index != scope.row.brandCodes.length - 1
                        ? _c("span", [_vm._v("/")])
                        : _vm._e()
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              prop: "publishStatus",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.publishStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s("即将发布"))
                        ])
                      : _vm._e(),
                    row.publishStatus == 1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s("发布中"))
                        ])
                      : _vm._e(),
                    row.publishStatus == 2
                      ? _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v(_vm._s("已结束"))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "类目名称",
              prop: "goodsClassifyId",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getDictValueByGoodsClassifyId(row)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "开始时间",
              prop: "startTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "结束时间",
              prop: "endTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              "render-header": _vm.renderSortHeader,
              prop: "sort",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        min: 0,
                        οnkeyup: "value=value.replace(/[^\\d]/g,'')",
                        oninput: "value=value.replace(/^\\.+|[^\\d]/g,'')",
                        placeholder: "请输入内容"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.putSortArticle(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "是否启用",
              "show-overflow-tooltip": "",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: "" },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommend",
              label: "是否推荐",
              "show-overflow-tooltip": "",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        disabled: scope.row.articleType != "Blog"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.recommend,
                        callback: function($$v) {
                          _vm.$set(scope.row, "recommend", $$v)
                        },
                        expression: "scope.row.recommend"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "newed",
              label: "是否最新",
              "show-overflow-tooltip": "",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        disabled: scope.row.articleType != "Blog"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.newed,
                        callback: function($$v) {
                          _vm.$set(scope.row, "newed", $$v)
                        },
                        expression: "scope.row.newed"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "operator", label: "操作人", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteSingle(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.addVisible,
                "close-on-click-modal": false,
                width: "950px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.addArticle,
                    "label-position": "left",
                    size: "small",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "栏目", prop: "articleType" } },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "article_type",
                          placeholder: "栏目"
                        },
                        on: { change: _vm.articleChange },
                        model: {
                          value: _vm.addArticle.articleType,
                          callback: function($$v) {
                            _vm.$set(_vm.addArticle, "articleType", $$v)
                          },
                          expression: "addArticle.articleType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示国家", prop: "countryCodes" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { indeterminate: _vm.isIndeterminate },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll"
                          }
                        },
                        [_vm._v("全选")]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { width: "600px" },
                          on: { change: _vm.handleCheckedCitiesChange },
                          model: {
                            value: _vm.addArticle.countryCodes,
                            callback: function($$v) {
                              _vm.$set(_vm.addArticle, "countryCodes", $$v)
                            },
                            expression: "addArticle.countryCodes"
                          }
                        },
                        _vm._l(_vm.countrysDicts, function(country) {
                          return _c(
                            "el-checkbox",
                            {
                              key: country.code,
                              attrs: { label: country.code }
                            },
                            [
                              _vm._v(
                                _vm._s(country.name) +
                                  "(" +
                                  _vm._s(country.code) +
                                  ")"
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "fileType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function($event) {
                              return _vm.fileTypeChange()
                            }
                          },
                          model: {
                            value: _vm.addArticle.fileType,
                            callback: function($$v) {
                              _vm.$set(_vm.addArticle, "fileType", $$v)
                            },
                            expression: "addArticle.fileType"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.imageShowStatus,
                                label: "image"
                              }
                            },
                            [_vm._v("图文")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.videoShowStatus,
                                label: "video"
                              }
                            },
                            [_vm._v("视频")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "brandCodes" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "180px" },
                          attrs: { placeholder: "品牌", clearable: "" },
                          on: { change: _vm.brandChange },
                          model: {
                            value: _vm.brand,
                            callback: function($$v) {
                              _vm.brand = $$v
                            },
                            expression: "brand"
                          }
                        },
                        [
                          _vm.myTenantid == "1001001000" ||
                          _vm.myTenantid == "1001001006"
                            ? _c(
                                "el-option",
                                {
                                  attrs: {
                                    disabled: _vm.aigoBrandStatus,
                                    value: "AigoStar"
                                  }
                                },
                                [_vm._v("AigoStar")]
                              )
                            : _vm._e(),
                          _vm.myTenantid == "1001001000" ||
                          _vm.myTenantid == "1001001006"
                            ? _c(
                                "el-option",
                                {
                                  attrs: {
                                    disabled: _vm.noblezaBrandStatus,
                                    value: "Nobleza"
                                  }
                                },
                                [_vm._v("Nobleza")]
                              )
                            : _vm._e(),
                          _vm.myTenantid == "1001002001"
                            ? _c("el-option", { attrs: { value: "Unico" } }, [
                                _vm._v("Unico")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.addArticle.articleType == "Video"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "类目", prop: "goodsClassifyId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "180px" },
                              attrs: { placeholder: "类目", clearable: "" },
                              model: {
                                value: _vm.addArticle.goodsClassifyId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addArticle,
                                    "goodsClassifyId",
                                    $$v
                                  )
                                },
                                expression: "addArticle.goodsClassifyId"
                              }
                            },
                            _vm._l(_vm.goodsClassifyDicts, function(item) {
                              return _c("el-option", {
                                key: item.goodsClassifyId,
                                attrs: {
                                  label: item.title,
                                  value: item.goodsClassifyId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布时间", prop: "startTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.valuePicker,
                          callback: function($$v) {
                            _vm.valuePicker = $$v
                          },
                          expression: "valuePicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { prop: "imageUrl", label: "图片" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("imgUpload", {
                            ref: "imageUpload",
                            attrs: { img: _vm.imageUrl },
                            on: { event: _vm.changeUrl }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "150px" },
                          attrs: { span: 10 }
                        },
                        [_c("span", [_vm._v("建议尺寸：466*280，<=200KB")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          oninput: "value=value.replace(/^\\.+|[^\\d]/g,'')"
                        },
                        model: {
                          value: _vm.addArticle.sort,
                          callback: function($$v) {
                            _vm.$set(_vm.addArticle, "sort", $$v)
                          },
                          expression: "addArticle.sort"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用", prop: "status" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce669" },
                        model: {
                          value: _vm.addArticle.status,
                          callback: function($$v) {
                            _vm.$set(_vm.addArticle, "status", $$v)
                          },
                          expression: "addArticle.status"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.addArticle.articleType == "Blog"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否最新", prop: "newed" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce669" },
                            model: {
                              value: _vm.addArticle.newed,
                              callback: function($$v) {
                                _vm.$set(_vm.addArticle, "newed", $$v)
                              },
                              expression: "addArticle.newed"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addArticle.articleType == "Blog"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否推荐", prop: "recommend" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce669" },
                            model: {
                              value: _vm.addArticle.recommend,
                              callback: function($$v) {
                                _vm.$set(_vm.addArticle, "recommend", $$v)
                              },
                              expression: "addArticle.recommend"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addArticle.fileType == "image"
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "800px" },
                          attrs: { label: "多语言配置" }
                        },
                        [
                          _c(
                            "el-menu",
                            {
                              staticClass: "el-menu-demo",
                              attrs: {
                                "default-active": _vm.contentIndex,
                                mode: "horizontal"
                              },
                              on: { select: _vm.handleContentSelect }
                            },
                            _vm._l(_vm.langs, function(item, index) {
                              return _c(
                                "el-menu-item",
                                { key: item.code, attrs: { index: item.code } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.name) +
                                      "\n                        "
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("标题：")]),
                              _c("el-input", {
                                staticStyle: {
                                  "margin-top": "20px",
                                  width: "450px"
                                },
                                attrs: {
                                  placeholder: "请输入标题",
                                  label: "标题"
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "55px" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.contentToOtherLang }
                                },
                                [_vm._v("同步到其他语言")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _c("span", [_vm._v("内容：")]),
                              _c("wangEditer", {
                                ref: "wangEditer",
                                staticStyle: { "margin-top": "10px" },
                                attrs: { conten: _vm.contentValue }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.openEdit }
                                },
                                [_vm._v("编辑富文本源码")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addArticle.fileType == "video"
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "800px" },
                          attrs: { label: "视频" }
                        },
                        [
                          _c(
                            "el-menu",
                            {
                              staticClass: "el-menu-demo",
                              attrs: {
                                "default-active": _vm.contentIndex,
                                mode: "horizontal"
                              },
                              on: { select: _vm.handleVideoSelect }
                            },
                            _vm._l(_vm.langs, function(item, index) {
                              return _c(
                                "el-menu-item",
                                { key: item.code, attrs: { index: item.code } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.name) +
                                      "\n                        "
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("标题：")]),
                              _c("el-input", {
                                staticStyle: {
                                  "margin-top": "20px",
                                  width: "450px"
                                },
                                attrs: {
                                  placeholder: "请输入标题",
                                  label: "标题"
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _c("span", [_vm._v("上传视频：")]),
                              _c("videoUpload", {
                                ref: "videoUpload",
                                attrs: { video: _vm.videoUrl },
                                on: { event: _vm.changeVideoUrl }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.videoToOtherLang }
                                },
                                [_vm._v("同步到其他语言")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" 确认 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步到其他语言",
            visible: _vm.dialogFormVisible,
            "append-to-body": true,
            "before-close": _vm.closeCopyDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.copyIsIndeterminate },
                  on: { change: _vm.handleCopyCheckAllChange },
                  model: {
                    value: _vm.copyCheckAll,
                    callback: function($$v) {
                      _vm.copyCheckAll = $$v
                    },
                    expression: "copyCheckAll"
                  }
                },
                [_vm._v("全选\n                ")]
              ),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCopyCheckedCitiesChange },
                  model: {
                    value: _vm.copyLangs,
                    callback: function($$v) {
                      _vm.copyLangs = $$v
                    },
                    expression: "copyLangs"
                  }
                },
                _vm._l(_vm.copyLangList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: { click: _vm.closeCopyDialog }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onCopySubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复制文章",
            visible: _vm.dialogCopyVisible,
            "append-to-body": true,
            "before-close": _vm.closeCopyDialog,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCopyVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "将所选文章复制到" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择栏目" },
                      model: {
                        value: _vm.copyType,
                        callback: function($$v) {
                          _vm.copyType = $$v
                        },
                        expression: "copyType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "企业新闻", value: "News" }
                      }),
                      _c("el-option", {
                        attrs: { label: "博客", value: "Blog" }
                      }),
                      _c("el-option", {
                        attrs: { label: "标签", value: "Tag" }
                      }),
                      _c("el-option", {
                        attrs: { label: "品牌动态", value: "Dynamics" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "190px", "margin-top": "30px" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.copyArticle }
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.closeCopyDialog }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑富文本源码",
            visible: _vm.dialogEditContentVisible,
            "append-to-body": true,
            "before-close": _vm.closeCopyDialog,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditContentVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 20 },
                    model: {
                      value: _vm.contentCode,
                      callback: function($$v) {
                        _vm.contentCode = $$v
                      },
                      expression: "contentCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "190px", "margin-top": "30px" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.editContentCode }
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.closeCopyDialog }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }