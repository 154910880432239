var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      placeholder: "职位名称",
                      oninput: "value=value.replace(/[%]/g,'')",
                      clearable: ""
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.name,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "name", $$v)
                      },
                      expression: "query.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      codeType: "job_type",
                      placeholder: "职位类型"
                    },
                    model: {
                      value: _vm.query.jobType,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "jobType", $$v)
                      },
                      expression: "query.jobType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: { multiple: true, placeholder: "国家" },
                      model: {
                        value: _vm.query.countryCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCodes", $$v)
                        },
                        expression: "query.countryCodes"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("\n                    查询\n                ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [_vm._v("\n                    重置\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("\n            添加\n        ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              type: "index",
              align: "center",
              width: "50px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "职位类型",
              prop: "jobType",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "job_type" },
                      model: {
                        value: scope.row.jobType,
                        callback: function($$v) {
                          _vm.$set(scope.row, "jobType", $$v)
                        },
                        expression: "scope.row.jobType"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "职位名称",
              prop: "name",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "办公地点",
              prop: "joinUsCountrys",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.joinUsCountrys, function(
                    item,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: index },
                      [
                        _c("dataText", {
                          staticStyle: { display: "inline" },
                          attrs: { codeType: "countrys" },
                          model: {
                            value: item.countryCode,
                            callback: function($$v) {
                              _vm.$set(item, "countryCode", $$v)
                            },
                            expression: "item.countryCode"
                          }
                        }),
                        scope.row.joinUsCountrys.length != 1 &&
                        index != scope.row.joinUsCountrys.length - 1
                          ? _c("span", [_vm._v("、")])
                          : _vm._e()
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "排序",
              prop: "sort",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "createTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "更新时间",
              prop: "updateTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteSingle(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.addVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.addJoin,
                    "label-position": "left",
                    size: "small",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "jobType", label: "职位类型" } },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "job_type",
                          placeholder: "职位类型"
                        },
                        model: {
                          value: _vm.addJoin.jobType,
                          callback: function($$v) {
                            _vm.$set(_vm.addJoin, "jobType", $$v)
                          },
                          expression: "addJoin.jobType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "办公国家" } },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          multiple: "",
                          codeType: "countrys",
                          placeholder: "国家"
                        },
                        model: {
                          value: _vm.countryCodes,
                          callback: function($$v) {
                            _vm.countryCodes = $$v
                          },
                          expression: "countryCodes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { min: 0 },
                        model: {
                          value: _vm.addJoin.sort,
                          callback: function($$v) {
                            _vm.$set(_vm.addJoin, "sort", $$v)
                          },
                          expression: "addJoin.sort"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "800px" },
                      attrs: { label: "多语言配置" }
                    },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.contentIndex,
                            mode: "horizontal"
                          },
                          on: { select: _vm.handleContentSelect }
                        },
                        _vm._l(_vm.langs, function(item, index) {
                          return _c(
                            "el-menu-item",
                            { key: item.code, attrs: { index: item.code } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.name) +
                                  "\n                    "
                              )
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("职位名称：")]),
                          _c("el-input", {
                            staticStyle: {
                              "margin-top": "20px",
                              width: "600px"
                            },
                            attrs: {
                              placeholder: "输入名称",
                              label: "职位名称"
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("办公地点：")]),
                          _c("el-input", {
                            staticStyle: {
                              "margin-top": "20px",
                              width: "600px"
                            },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 10 },
                              placeholder: "输入办公地点",
                              label: "办公地点"
                            },
                            model: {
                              value: _vm.location,
                              callback: function($$v) {
                                _vm.location = $$v
                              },
                              expression: "location"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("职位描述：")]),
                          _c("el-input", {
                            staticStyle: {
                              "margin-top": "20px",
                              width: "600px"
                            },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 10 },
                              placeholder: "输入职位描述",
                              label: "职位描述"
                            },
                            model: {
                              value: _vm.describe,
                              callback: function($$v) {
                                _vm.describe = $$v
                              },
                              expression: "describe"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("联系我们：")]),
                          _c("el-input", {
                            staticStyle: {
                              "margin-top": "20px",
                              width: "600px"
                            },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 10 },
                              placeholder: "输入联系我们",
                              label: "联系我们"
                            },
                            model: {
                              value: _vm.contactUs,
                              callback: function($$v) {
                                _vm.contactUs = $$v
                              },
                              expression: "contactUs"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" 确认 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }